import { FormikProps, FormikValues } from "formik";
import UploadDniCard from "../../components/UploadDniCard/UploadDniCard";
import LivenessValidationCard from "../../components/LivenessValidationCard/LivenessValidationCard";
import {
  deleteIndividualDraft,
  getCountryList,
  getDraft,
  getDrafts,
  getIndividualQuestions,
  getPhysicalActivities,
  sendIndividualDocument,
  sendStep,
  validateRenaper,
} from "../../connectors/connectors";
import {
  emailRequired,
  cuitRequired,
  radioOrSingleSelectRequired,
  smallStringRequired,
  dniRequired,
  tramitNumberRequired,
  phone,
  largeStringRequired,
  fileRequired,
  mediumStringRequired,
  multipleSelectRequired,
  cbuRequired,
  checkboxRequired,
  smallString,
  zipCodeRequired,
  noSpecialCharactersRequired,
  checkbox,
  cbuNotRequired,
} from "../../validations";
import {
  CivilStatus,
  Step,
  Template,
  Option,
  PitbullActivities,
  PitbullCountries,
  Field,
  MidgardQuestions,
  ComponentStep,
  PersonType,
  IdentityType,
  IdentityVersion,
  GenderInitials,
  BasicStep,
  PreconfirmationStep,
  MidgardQuestion,
  StepEndpoint,
  StepOnePF,
  StepTwoPF,
  StepThreePF,
  PitbullCountry,
  StepFourPF,
  InputType,
} from "../../types";
import bodyIcon from "../../assets/images/icons/ui/body.svg";
import { authEmail } from "../../connectors/connectors";
import {
  capitalizeString,
  parseDate,
  parseGenderFromInitial,
  parseGenderToInitial,
  parseTelephone,
} from "../../app/utils";
import {
  updateSignatureRequestId,
  updateToken,
} from "../../reducers/authReducer";
import axios from "axios";
import TokenStep from "../../components/TokenStep";
import { updateRenaperData } from "../../reducers/renaperReducer";
import {
  addSendedStep,
  onUpdateCompletedStep,
  onUpdateDraftUUID,
  onUpdateFormData,
  onUpdateHasExternalInformation,
  onUpdateReachedStep,
  onUpdateStep,
  onUpdateSubStep,
} from "../../reducers/onboardingDataReducer";
import { AppDispatch, RootState } from "../../app/store";
import InteractiveInformation from "../../components/InteractiveInformation/InteractiveInformation";
import {
  addOneValidation,
  replaceValidations,
} from "../../reducers/validationsReducer";
import {
  asCuit,
  cityOptions,
  getCompanyData,
  parseStateAddress,
} from "../../utils";
import { maxFileSize, validDniTypes } from "../../utils";
import { showToastMessage } from "@almafintech/react-components";
import { genericToastifyError } from "../../utils";
import { onUpdateInfoStep } from "../../reducers/infoStepReducer";
import { deleteDoc, doc, getFirestore } from "@firebase/firestore";
import { logAnalyticsAndHotjarEvent } from "../../main";
import EditIcon from "../../assets/images/icons/ui/edit.svg?react";

import * as Sentry from "@sentry/react";

const genderOptions = [
  { value: "M", label: "Masculino" },
  { value: "F", label: "Femenino" },
  { value: "X", label: "Otro" },
];

const pepOptions = [
  { value: "DIRECT", label: "Sí, directamente" },
  { value: "FAMILY", label: "Sí, por vínculo familiar" },
  { value: "NONE", label: "No" },
];

const civilStatusOptions: { value: CivilStatus; label: string }[] = [
  { value: "SINGLE", label: "Soltero/a" },
  { value: "MARRIED", label: "Casado/a" },
  { value: "WIDOWED", label: "Viudo/a" },
  { value: "DIVORCED", label: "Divorciado/a" },
];

const employmentStatusOptions = [
  {
    value: "DEPENDENCY_RELATIONSHIP",
    label: "Trabajo en relación de dependencia",
  },
  { value: "MONOTAX", label: "Soy monotributista" },
  { value: "AUTONOMOUS", label: "Soy autónomo/a" },
  { value: "STUDENT", label: "Soy estudiante" },
  { value: "RETIRED", label: "Soy jubilado/a" },
  { value: "UNEMPLOYED", label: "No tengo empleo actualmente" },
];

const employmentYearsOptions = [
  { value: "Menos de un año", label: "Menos de 1 año" },
  { value: "1 año", label: "1 año" },
  { value: "2 años", label: "2 años" },
  { value: "3 años", label: "3 años" },
  { value: "4 años", label: "4 años" },
  { value: "5 años", label: "5 años" },
  { value: "6 años", label: "6 años" },
  { value: "7 años", label: "7 años" },
  { value: "8 años", label: "8 años" },
  { value: "9 años", label: "9 años" },
  { value: "Más de 10 años", label: "10 años o más" },
];

const yesOrNoBoolOptions = [
  { value: "SI", label: "Sí" },
  { value: "NO", label: "No" },
];

const questionsToFields = (questions: MidgardQuestions) =>
  questions.map((question) => {
    const options = question.choices.map((choice) => {
      return { value: choice.option, label: choice.option };
    });
    const isDev = import.meta.env.VITE_ENVIRONMENT === "dev";

    const isRiskProfileQuestion = isDev
      ? question.externalCode === "1f794542-ccb7-4128-892b-26f3a0c676e4"
      : question.externalCode === "30d210d5-189c-4033-a694-dcf65a254bfd";

    return {
      name: `investmentProfile.${question.externalCode}`,
      type: "select",
      label: question.text,
      placeholder: "Seleccioná una opción",
      validation: radioOrSingleSelectRequired(options),
      options,
      tooltip: isRiskProfileQuestion
        ? {
            label: "Ver tabla de riesgo",
            modal: "modal-risk-profile",
          }
        : undefined,
      selectType: "single",
      initialValue: "",
    };
  }) as Field[];

const getActivities = async (): Promise<PitbullActivities> => {
  try {
    const { data } = await getPhysicalActivities();
    return data;
  } catch (error) {
    throw new Error("Error al obtener las actividades");
  }
};

const getCountries = async (): Promise<PitbullCountries> => {
  try {
    const { data } = await getCountryList();
    return data;
  } catch (error) {
    throw new Error("Error al obtener los países");
  }
};

const getQuestions = async (): Promise<MidgardQuestions> => {
  try {
    const { data } = await getIndividualQuestions();
    return data;
  } catch (error) {
    throw new Error("Error al obtener las preguntas");
  }
};

const stepOne: Step = {
  step: 1,
  name: "Cuenta personal",
  titleOfStep: "Información básica",
  disableBackNavigation: true,
  fields: [
    {
      initialValue: "",
      type: "cuit",
      name: "cuit",
      label: "CUIT / CUIL",
      placeholder: "XX-XXXXXXXX-X",
      validation: cuitRequired,
    },
    {
      initialValue: "",
      type: "email",
      name: "email",
      label: "Mail",
      validation: emailRequired,
      placeholder: "direccion@mail.com",
    },
    {
      name: "referredBool",
      type: "radio",
      label: "¿Fuiste referido?",
      validation: radioOrSingleSelectRequired(yesOrNoBoolOptions),
      initialValue: "",
      options: yesOrNoBoolOptions,
      disabledByUrlParams: "ref",
    },
    {
      conditional: {
        name: "referredBool",
        value: "SI",
      },
      initialValue: "",
      type: "text",
      name: "referrer",
      label: "Nombre o mail de quien te refirió",
      validation: smallStringRequired,
      disabledByUrlParams: "ref",
    },
    ...(getCompanyData().companyName === "ALLARIA" ||
    getCompanyData().companyName === "AGROFINANZAS"
      ? [
          {
            name: "labelReferrer",
            type: "label" as InputType,
            label: "",
            initialValue: "",
            text: (
              <p
                style={{
                  color: "#001C40",
                  fontSize: "0.7025rem",
                  fontWeight: 400,
                }}
              >
                Si estás solicitando la apertura de tu cuenta comitente para
                adherir al{" "}
                <a
                  style={{
                    color: "#0150B7",
                    fontWeight: 600,
                    textDecorationLine: "underline",
                  }}
                  href="https://www.argentina.gob.ar/normativa/nacional/ley-27743-401268/normas-modifican"
                >
                  blanqueo (ley 27.743)
                </a>
                , seleccioná "<strong>Sí</strong>" en "
                <strong>¿Fuiste referido?</strong>" y luego indicá "
                <strong>cuenta blanqueo</strong>" en el campo de texto.
              </p>
            ),
          },
        ]
      : []),
  ],
  onContinue: async (values: FormikValues, dispatch) => {
    try {
      if (!dispatch || !values) throw new Error("Error: dispatch not found");

      //To prevent failed loop
      dispatch(onUpdateInfoStep(null));

      const { email, cuit } = values;
      if (cuit.startsWith("30") || cuit.startsWith("33")) {
        dispatch(onUpdateInfoStep(null));
        dispatch(onUpdateStep("information-step"));
        throw new Error("CUIT no válido");
      } else {
        const res = await authEmail({
          email,
          companyType: getCompanyData().companyName,
        });

        dispatch(updateSignatureRequestId(res.data.signatureRequestId));
        const identityDocument = cuit.slice(2, 10);
        logAnalyticsAndHotjarEvent("step_information_basic_completed_pf");

        if (email) {
          Sentry.setUser({ email });
        }

        return { newValuesToForm: { identityDocument } };
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      throw new Error("Error al enviar los datos");
    }
  },
};

const stepTwo: ComponentStep = {
  step: 2,
  name: "Mail",
  component: (dispatch, state, formik) => {
    if (!state || !dispatch || !formik)
      throw new Error("Error: state, dispatch or formik not found");

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const completeSteps = async (steps: any, isCheckingESCO?: boolean) => {
      const isActiveAccount = isCheckingESCO; //Has information in ESCO

      //isCheckingESCO means is checking getDrafts endpoint
      const stepOne = steps.find((step: StepOnePF) => step.personalInformation);
      const {
        personalInformation: {
          birthDate,
          birthCountry,
          citizenship,
          civilStatus,
          cuit,
          gender,
          name,
          pep,
          pepDetails,
          telephone,
          lastName,
          identityDocument,
          referrer,
          addresses,
          spouse,
        },
      } = stepOne;

      const parsePep = (pep: string) => {
        if (pep === null) {
          if (isActiveAccount)
            return ""; //Esco doesn't give us specific information about pep, so return empty string
          else return "NONE";
        } else return pep;
      };

      const stepOneParsed = {
        birthDate: birthDate,
        birthCountry: birthCountry,
        citizenship: citizenship,
        civilStatus: civilStatus,
        cuit: cuit,
        gender: parseGenderToInitial(gender),
        lastName: lastName,
        name: name,
        pep: parsePep(pep),
        pepMotive: pepDetails,
        telephone: parseTelephone(telephone),
        identityDocument: identityDocument?.idNumber || cuit.slice(2, 10),
        tramitNumber: identityDocument?.procedureId,
        referredBool: referrer !== null ? true : false,
        addresses: {},
        spouseName: undefined,
        spouseLastName: undefined,
        spouseCuil: undefined,
      };
      if (addresses && addresses.length > 0) {
        stepOneParsed.addresses = {
          zipCode: addresses[0].zipCode,
          state: addresses[0].state,
          municipality: addresses[0].city,
          fullAddress: `${addresses[0].street} ${addresses[0].number} ${addresses[0].city}`,
          floor: addresses[0].floor,
          apartment: addresses[0].apartment,
          country: addresses[0].country,
        };
      }
      if (spouse) {
        stepOneParsed.spouseName = spouse.name;
        stepOneParsed.spouseLastName = spouse.lastName;
        stepOneParsed.spouseCuil = spouse.cuit;
      }
      let stepTwoParsed;

      //STEP TWO
      const stepTwo = steps.find((step: StepTwoPF) => step.employmentProfile);

      if (stepTwo) {
        const {
          employmentProfile: {
            activities,
            bankAccounts,
            employmentStatus,
            foreignTaxDetails,
            years,
          },
        } = stepTwo;

        //For the moment, the 'activities' coming from ESCO (endpoint getDrafts) are emptied because they are not parsed from the backend
        const parsedActivities =
          isActiveAccount || activities.length === 0 ? "" : activities;

        stepTwoParsed = {
          foreignTaxDetailsBool: foreignTaxDetails ? "SI" : "NO",
          bankAccountsBool:
            bankAccounts && bankAccounts.length > 0 ? "SI" : "NO",
          employmentProfile: {
            activities: parsedActivities,
            bankAccounts,
            employmentStatus,
            foreignTaxDetails: foreignTaxDetails,
            years: years,
          },
        };
      }
      let stepThreeParsed;

      //STEP THREE
      const stepThree = steps.find(
        (step: StepThreePF) => step.investmentProfile
      );

      const questions = await getQuestions();

      const answers: string[] = stepThree?.investmentProfile?.choices || [];

      const investmentProfileParsed = () => {
        const result: Record<string, string> = {};
        questions.forEach((question) => {
          const res = findAnswerAbaoutQuestion(question);
          if (res) {
            result[question.externalCode] = res.option;
          }
        });
        return result;
      };

      const findAnswerAbaoutQuestion = (question: MidgardQuestion) =>
        question.choices.find((choice) =>
          answers.find((answer) => answer === choice.externalCode)
        );

      if (stepThree) {
        stepThreeParsed = {
          investmentProfile: investmentProfileParsed(),
        };
      }

      const newValues = {
        ...stepOneParsed,
        ...stepTwoParsed,
        ...stepThreeParsed,
      };

      formik?.setValues(
        (prevValues) => ({
          ...prevValues,
          ...newValues,
        }),
        false
      );

      const stepOneToStore = {
        ...stepOne,
        personalInformation: {
          ...stepOne.personalInformation,
          birthDate: stepOne.personalInformation.birthDate,
        },
        referrer: formik.values.referrer,
        "@type": "1_PHYSICAL",
      };

      dispatch(addSendedStep(stepOneToStore));
      dispatch(onUpdateFormData(newValues));

      if (stepTwo) {
        const stepTwoToStore = { ...stepTwo, "@type": "2_PHYSICAL" };
        dispatch(addSendedStep(stepTwoToStore));
      }
      if (stepThree) {
        const stepThreeToStore = { ...stepThree, "@type": "3_PHYSICAL" };
        dispatch(addSendedStep(stepThreeToStore));
      }
      if (stepOne.validations)
        dispatch(replaceValidations(stepOne.validations));

      return { stepOneParsed, stepTwoParsed, stepOne, stepTwo };
    };

    const onAuthorize = async (draftUUID?: string) => {
      try {
        if (!draftUUID || !state || !formik)
          throw new Error("DraftUUID or state not found");

        const { cuit } = formik.values;
        //Check if there is a draft with the same cuit
        try {
          const draftsResponse = await getDrafts({
            cuit,
            personType: "PHYSICAL",
          });

          const stepsDafts = draftsResponse?.data?.steps;

          if (stepsDafts?.length > 0) {
            await completeSteps(stepsDafts, true);
            dispatch(onUpdateHasExternalInformation(true));
            dispatch(onUpdateInfoStep("ACTIVE_ACCOUNT"));
          }
        } catch (error) {
          Sentry.captureException(error);
          console.log(error, "Error on getDrafts");
        }
        if (state.infoStep.type !== "ACTIVE_ACCOUNT") {
          try {
            const draftResponse = await getDraft({
              draftUUID,
            });

            const steps = draftResponse.data.steps;

            if (steps.length > 0) {
              await completeSteps(steps);
              dispatch(onUpdateInfoStep("PENDING_REGISTRY_REMOTE"));
            }
          } catch (error) {
            console.log("Error on getDraft", error);
          }
        }
      } catch (error) {
        console.log(error, "Error on authorize");
        Sentry.captureException(error);
      }
    };

    const onSuccess = async () => {
      const { type } = state.infoStep;
      logAnalyticsAndHotjarEvent("step_mail_completed_pf");
      if (type !== null) {
        dispatch(onUpdateStep("information-step"));
      } else {
        dispatch(
          onUpdateFormData({
            identityDocument: formik.values.cuit.slice(2, 10),
          })
        );
        dispatch(onUpdateCompletedStep(2));
        dispatch(onUpdateReachedStep(3));
        dispatch(onUpdateStep(3));
      }
    };

    return (
      <TokenStep
        previousStep={1}
        onSuccess={onSuccess}
        onAuthorize={onAuthorize}
        formik={formik}
      />
    );
  },
  disableBackNavigation: true,
};

const stepThree = (countries: PitbullCountry[]): Step => ({
  step: 3,
  name: "Identidad",
  disableButtonBack: true,
  subSteps: [
    {
      step: 1,
      fields: [
        {
          placeholder: "XX.XXX.XXX",
          initialValue: "",
          type: "dni",
          name: "identityDocument",
          label: "DNI",
          validation: dniRequired,
          disabled: true,
          tooltip: {
            label: <EditIcon />,
            modal: "modal-edit-dni",
            external: true,
          },
        },
        {
          placeholder: "XXXXXXXXXXX",
          initialValue: "",
          type: "text",
          name: "tramitNumber",
          label: "Número de trámite",
          tooltip: {
            label: "¿Dónde lo encuentro?",
            modal: "modal-tramit-number",
          },
          validation: tramitNumberRequired,
          infoText: "Ingresá los 11 dígitos, incluyendo ceros iniciales.",
        },
        {
          name: "gender",
          type: "multiple-radio",
          placeholder: "Seleccioná una opción",
          validation: radioOrSingleSelectRequired(genderOptions),
          initialValue: "",
          options: genderOptions,
          label: "Género",
        },
      ],
      onContinue: async (values: FormikValues, dispatch, state) => {
        try {
          if (!state) throw new Error("Error reading state at stepThree");

          const { gender, tramitNumber, identityDocument } = values;
          const { data } = await validateRenaper({
            gender,
            order: tramitNumber,
            number: identityDocument,
          });

          const failedStatus = ["FAILED", "CHECK"];

          // TYPES OF STATUS "OK" | "FAILED" | "CHECK"
          if (!data || !data.status || failedStatus.includes(data.status)) {
            Sentry.captureException(new Error(), {
              extra: { status: data?.status, data: data.validations[0] },
            });
            throw new Error("FAILED at validate Renaper");
          } else if (data.status === "OK" && dispatch) {
            const information = data.validations[0].information;

            // Convert string to JSON
            const informationParsed = JSON.parse(information);
            dispatch(
              updateRenaperData({
                ...informationParsed,
                externalCode: data.validations[0].externalCode,
              })
            );
            dispatch(
              addOneValidation({
                name: "RENAPER",
                externalCode: data.validations[0].externalCode,
              })
            );
            const { isExternalInformation } = state.onboardingData;
            const {
              names,
              lastNames,
              birthdate,
              countryBirth,
              streetAddress,
              numberStreet,
              country,
              city,
              nationality,
              municipality,
            } = informationParsed;

            const parsedCountry = countries.find(({ name }) => {
              const lowerCaseName = name.toLowerCase();

              return (
                countryBirth?.toLowerCase() === lowerCaseName ||
                country?.toLowerCase() === lowerCaseName
              );
            })?.name;

            const parsedCitizenship = countries.find(({ citizenship }) => {
              const lowerCaseCitizenship = citizenship?.toLowerCase();

              // Typo in Renaper API
              if (nationality === "COSTARICENSE") {
                return "costarricense" === lowerCaseCitizenship;
              }

              return nationality?.toLowerCase() === lowerCaseCitizenship;
            })?.citizenship;

            const getAddress = () => {
              let cityParsed = city;
              let municipalityParsed = municipality;
              if (city === "SIN INFORMAR") cityParsed = "";
              if (municipality === "SIN INFORMAR") municipalityParsed = "";

              if (!isExternalInformation) {
                return {
                  birthCountry: parsedCountry,
                  addresses: {
                    fullAddress: `${streetAddress} ${numberStreet} ${cityParsed} ${municipalityParsed}`,
                    country: parsedCountry,
                  },
                };
              }
            };
            const body = {
              name: names,
              lastName: lastNames,
              birthDate: birthdate,
              citizenship: parsedCitizenship,
              ...getAddress(),
            };

            dispatch(onUpdateFormData({ ...body }));
            return { newValuesToForm: body };
          }
        } catch (error) {
          console.log(error, "error en el try de renaper");
          Sentry.captureException(error);
          throw new Error("Error al validar renaper");
        }
      },
    },
    {
      step: 2,
      label: "uploadDNI",
      subtitle: "Cargá fotos del frente y dorso de tu DNI.",
      component: (dispatch, state, formik) => {
        return (
          <UploadDniCard dispatch={dispatch} state={state} formik={formik} />
        );
      },
      insideForm: true,
    },
    {
      step: 3,
      component: (dispatch, state, formik) => {
        return (
          <LivenessValidationCard
            dispatch={dispatch}
            state={state}
            formik={formik}
          />
        );
      },
      insideForm: true,
    },
  ],
  disableBackNavigation: true,
});

const stepFour = (
  countriesOptions: Option[],
  citizenshipOptions: Option[]
): Step => ({
  step: 4,
  name: "Datos personales",
  disableButtonBack: true,
  subSteps: [
    {
      step: 1,
      fields: [
        {
          name: "name",
          type: "text",
          label: "Nombre/s",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          disabled: true,
        },
        {
          name: "lastName",
          type: "text",
          label: "Apellido/s",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          disabled: true,
          formatInPreConfirmation: (value) => capitalizeString(value),
        },
        {
          name: "citizenship",
          type: "select",
          label: "Nacionalidad",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          options: citizenshipOptions,
          disabled: (state) => !!state?.renaper?.nationality,
          onChange: (value, setFieldValue) => {
            setFieldValue("citizenship", value);
          },
        },
        {
          name: "birthDate",
          type: "text",
          label: "Fecha de nacimiento",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          disabled: true,
        },
        {
          name: "civilStatus",
          type: "select",
          label: "Estado civil",
          placeholder: "Seleccioná una opción",
          validation: radioOrSingleSelectRequired(civilStatusOptions),
          options: civilStatusOptions,
          selectType: "single",
          initialValue: "",
        },
        {
          name: "spouseName",
          type: "text",
          label: "Nombre/s del cónyuge",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          conditional: {
            name: "civilStatus",
            value: "MARRIED",
          },
        },
        {
          name: "spouseLastName",
          type: "text",
          label: "Apellido/s del cónyuge",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          conditional: {
            name: "civilStatus",
            value: "MARRIED",
          },
        },
        {
          name: "spouseCuil",
          type: "cuit",
          label: "CUIL del cónyuge",
          validation: cuitRequired,
          initialValue: "",
          placeholder: "XX-XXXXXXXX-X",
          conditional: {
            name: "civilStatus",
            value: "MARRIED",
          },
          onBlur: async (values, _, setFieldError) => {
            const value = values.spouseCuil;
            const cuit = values.cuit;

            if (cuit === value && setFieldError) {
              setFieldError("spouseCuil", "Debe ser distinto al tuyo.");
            }
          },
          onChange: async (
            _values,
            setFieldValue,
            _,
            setFieldTouched,
            element
          ) => {
            //If the spouse CUIL value is the same as the user's CUIT, sets an error on the spouse CUIL field.
            const value = element && element.target.value;
            await setFieldValue("spouseCuil", value);
            setFieldTouched && setFieldTouched("spouseCuil", true);
          },
        },
        {
          name: "telephone",
          type: "phone",
          label: "Teléfono celular",
          validation: phone,
          initialValue: "",
          infoText: (value: string | undefined) => {
            if (!value || (value && value.startsWith("54")))
              return "Con código de área sin 0 ni 15.";
          },
          formatInPreConfirmation: (value) =>
            value.startsWith("+") ? value : `+${value}`,
        },
        {
          name: "pep",
          type: "select",
          label: "¿Estás expuesto/a políticamente?",
          tooltip: {
            label: "¿Qué significa?",
            modal: "modal-PEP",
          },
          placeholder: "Seleccioná una opción",
          validation: radioOrSingleSelectRequired(pepOptions),
          options: pepOptions,
          selectType: "single",
          initialValue: "",
          preconfirmationLabel: "¿Expuesto/a políticamente?",
        },
        {
          conditional: {
            name: "pep",
            value: ["DIRECT", "FAMILY"],
          },
          name: "pepMotive",
          type: "textarea",
          label: "Describí brevemente la situación",
          validation: largeStringRequired,
          initialValue: "",
        },
      ],
    },
    {
      step: 2,
      fields: [
        {
          name: "addresses.country",
          type: "select",
          label: "País de residencia",
          placeholder: "Seleccioná una opción",
          validation: radioOrSingleSelectRequired(countriesOptions),
          options: countriesOptions,
          selectType: "single",
          initialValue: "",
          onChange: (_, setFieldValue) => {
            const fieldsToClear = {
              "addresses.fullAddress": "",
              "addresses.municipality": "",
              "addresses.stateSelect": "",
              "addresses.stateText": "",
              "addresses.state": "",
              "addresses.zipCode": "",
              "addresses.floor": "",
              "addresses.apartment": "",
            };

            Object.entries(fieldsToClear).forEach(([field, value]) => {
              setFieldValue(field, value);
            });
          },
        },
        {
          name: "addresses.fullAddress",
          type: "address",
          label: "Domicilio",
          validation: mediumStringRequired,
          initialValue: "",
          autoSelect: "addresses.fullAddress",
          country: "addresses.country",
          onChange: (values, setFieldValue) => {
            const zipCode = values.formattedAddress.postalCode;
            const state = values.formattedAddress.state;
            const city = values.formattedAddress.city;
            const municipality = values.formattedAddress.municipality;
            const street = values.formattedAddress.street;
            const number = values.formattedAddress.streetNumber;

            const fieldStateAddresses =
              values.formattedAddress.country === "Argentina"
                ? "addresses.stateSelect"
                : "addresses.stateText";

            const parsedStateAddress = parseStateAddress(state);

            setFieldValue("addresses.zipCode", zipCode);
            setFieldValue(fieldStateAddresses, parsedStateAddress);
            setFieldValue("addresses.state", parsedStateAddress);
            setFieldValue("addresses.city", city);
            setFieldValue("addresses.municipality", municipality);
            setFieldValue("addresses.street", street);
            setFieldValue("addresses.number", number);
          },
          autoComplete: "off",
          formatInPreConfirmation: (value) => value.split(",")[0], // Only show street
        },
        {
          name: "addresses.floor",
          type: "text",
          label: "Piso",
          validation: smallString,
          initialValue: "",
          columns: 3,
          maxLength: 10,
        },
        {
          name: "addresses.apartment",
          type: "text",
          label: "Departamento",
          validation: smallString,
          initialValue: "",
          columns: 3,
          maxLength: 10,
        },
        {
          name: "addresses.zipCode",
          type: "text",
          label: "Código postal",
          validation: zipCodeRequired,
          initialValue: "",
          columns: 3,
        },
        {
          name: "addresses.municipality",
          type: "text",
          label: "Localidad",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
        },

        {
          name: "addresses.stateSelect",
          type: "select",
          label: "Provincia",
          validation: radioOrSingleSelectRequired(cityOptions()),
          initialValue: "",
          options: cityOptions(),
          columns: 2,
          onChange: (value, setFieldValue) => {
            setFieldValue("addresses.state", value);
          },
          conditional: {
            name: "addresses.country",
            value: "Argentina",
          },
        },
        {
          name: "addresses.stateText",
          type: "text",
          label: "Provincia",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          onChange: (value, setFieldValue) => {
            setFieldValue("addresses.state", value);
          },
          conditional: {
            name: "addresses.country",
            value: countriesOptions
              .map((country) =>
                country.value.toLowerCase() !== "argentina"
                  ? country.value
                  : null
              )
              .filter((value): value is string => value !== null),
          },
        },
      ],
      onContinue: async (values: FormikValues, dispatch, state) => {
        if (!state || !state.validations || !dispatch)
          throw new Error("Error: state or dispatch not found");

        try {
          const {
            email,
            name,
            lastName,
            cuit,
            gender,
            identityDocument,
            tramitNumber,
            citizenship,
            civilStatus,
            telephone,
            birthDate,
            birthCountry,
            pep,
            addresses: {
              floor,
              apartment,
              state: stateAddress,
              city,
              street,
              number,
              municipality,
              country,
              zipCode,
            },
            referredBool,
            spouseCuil,
            spouseLastName,
            spouseName,
            referrer,
          } = values;

          const stepOne = state.onboardingData?.sendedSteps?.find(
            (step) => "personalInformation" in step
          ) as StepOnePF;

          const versionOfIdentity =
            state.renaper.copy ||
            stepOne.personalInformation.identityDocument.version;

          const creationDate = state.renaper.creationDate
            ? parseDate(state.renaper.creationDate)
            : stepOne.personalInformation.identityDocument.creationDate;

          const expirationDate = state.renaper.expirationDate
            ? parseDate(state.renaper.expirationDate)
            : stepOne.personalInformation.identityDocument.expirationDate;

          const parsedBirthDate = parseDate(birthDate);

          const step: StepOnePF = {
            "@type": "1_PHYSICAL",
            personalInformation: {
              name,
              lastName,
              cuit,
              gender: parseGenderFromInitial[gender as GenderInitials],
              identityDocument: {
                type: "DNI" as IdentityType,
                idNumber: identityDocument,
                procedureId: tramitNumber,
                version: versionOfIdentity as IdentityVersion,
                creationDate,
                expirationDate,
              },
              telephone: `+${telephone}`,
              citizenship,
              birthCountry,
              birthDate: parsedBirthDate,
              civilStatus,
              pep,
              pepDetails: pep === "NONE" ? undefined : values.pepMotive,
              addresses: [
                {
                  street,
                  number,
                  zipCode,
                  city,
                  state: parseStateAddress(stateAddress) || "",
                  country,
                  floor,
                  apartment,
                  municipality,
                },
              ],
              spouse:
                civilStatus === "MARRIED"
                  ? {
                      cuit: spouseCuil,
                      lastName: spouseLastName,
                      name: spouseName,
                    }
                  : undefined,
            },
            validations: state.validations,
            referrer: referredBool ? referrer : undefined,
          };

          const body = {
            companyType: getCompanyData().companyName,
            personType: state.activeTemplate.onboardingType as PersonType,
            email,
            steps: [step],
          };
          await sendStep({ stepId: 1, body });
          logAnalyticsAndHotjarEvent("step_personal_data_completed_pf");
          dispatch(addSendedStep(step));
        } catch (error) {
          console.log("Error step four: ", error);
          throw new Error("Error al enviar los datos en el paso 4");
        }
      },
    },
  ],
});

const stepFive = (
  activitiesOptions: Option[],
  countriesOptions: Option[]
): Step => ({
  step: 5,
  name: "Situación laboral",
  fields: [
    {
      name: "employmentProfile.employmentStatus",
      type: "select",
      label: "Situación laboral actual",
      placeholder: "Seleccioná una opción",
      validation: radioOrSingleSelectRequired(employmentStatusOptions),
      onChange: (values, setFieldValue, _, setFieldTouched) => {
        const activitiesField = "employmentProfile.activities";
        const yearsOfActivityField = "employmentProfile.years";

        setFieldValue(yearsOfActivityField, []);
        if (setFieldTouched) setFieldTouched(yearsOfActivityField, false);

        switch (values.toString()) {
          case "STUDENT":
            setFieldValue(activitiesField, ["Estudiante"]);
            break;
          case "RETIRED":
            setFieldValue(activitiesField, ["Jubilado/a y/o Pensionado/a"]);
            break;
          default:
            setFieldValue(activitiesField, []);
            break;
        }
      },
      options: employmentStatusOptions,
      selectType: "single",
      initialValue: "",
    },
    {
      name: "employmentProfile.years",
      type: "select",
      label: "Años de actividad",
      placeholder: "Seleccioná una opción",
      validation: radioOrSingleSelectRequired(employmentYearsOptions),
      conditional: {
        name: "employmentProfile.employmentStatus",
        value: ["DEPENDENCY_RELATIONSHIP", "MONOTAX", "AUTONOMOUS"],
      },
      options: employmentYearsOptions,
      selectType: "single",
      initialValue: "",
      dontScrollTo: true,
    },
    {
      name: "employmentProfile.activities",
      type: "select",
      label: "Rubro o actividad",
      placeholder: "Seleccioná las actividades que correspondan",
      validation: multipleSelectRequired(activitiesOptions),
      dynamicOptions: (formik: FormikProps<FormikValues>) => {
        const employmentProfile =
          formik.values.employmentProfile?.employmentStatus || "";
        switch (employmentProfile) {
          case "DEPENDENCY_RELATIONSHIP":
            return activitiesOptions.filter(({ value }) => {
              return [
                "Empleado Público",
                "Empleado/Apoderado",
                "Docente",
                "Diplomático/Funcionario Público",
                "Policía/Militar/otras Fuerzas de Seguridad",
              ].includes(value);
            });
          case "STUDENT":
            return activitiesOptions.filter(({ value }) => {
              return value === "Estudiante";
            });
          case "RETIRED":
            return activitiesOptions.filter(({ value }) => {
              return value === "Jubilado/a y/o Pensionado/a";
            });
          case "UNEMPLOYED":
            return activitiesOptions.filter(({ value }) => {
              return value === "Desocupado" || value === "Ama de Casa";
            });
          default:
            return activitiesOptions;
        }
      },
      selectType: "multiple",
      initialValue: "",
      showExternalBox: true,
    },
    {
      name: "foreignTaxDetailsBool",
      type: "radio",
      label: "¿Tenés residencia fiscal fuera de Argentina?",
      validation: radioOrSingleSelectRequired(yesOrNoBoolOptions),
      options: yesOrNoBoolOptions,
      initialValue: "",
    },
    {
      conditional: {
        name: "foreignTaxDetailsBool",
        value: "SI",
      },
      name: "employmentProfile.foreignTaxDetails.country",
      type: "select",
      label: "País de residencia fiscal",
      placeholder: "Seleccioná una opción",
      validation: radioOrSingleSelectRequired(countriesOptions),
      options: countriesOptions,
      selectType: "single",
      initialValue: "",
      preconfirmationLabel: "Residencia fiscal",
    },
    {
      conditional: {
        name: "foreignTaxDetailsBool",
        value: "SI",
      },
      name: "employmentProfile.foreignTaxDetails.idNumber",
      type: "text",
      label: "Identificación fiscal",
      validation: noSpecialCharactersRequired,
      initialValue: "",
    },
    {
      name: "bankAccountsBool",
      type: "radio",
      label: "¿Tenés una cuenta bancaria en Argentina?",
      validation: radioOrSingleSelectRequired(yesOrNoBoolOptions),
      options: yesOrNoBoolOptions,
      initialValue: "",
      preconfirmationLabel: "¿Cuenta bancaria argentina?",
    },
    {
      conditional: {
        name: "bankAccountsBool",
        value: "SI",
      },
      name: "employmentProfile.bankAccounts",
      type: "text",
      label: "Cuenta bancaria en Argentina",
      validation:
        getCompanyData().companyName === "ALLARIA" ||
        getCompanyData().companyName === "AGROFINANZAS"
          ? cbuNotRequired
          : cbuRequired,
      initialValue: "",
      placeholder: "Ingresá tu CBU o CVU",
      showOptionalLabel: true,
      infoText: "La cuenta ingresada debe ser de tu misma titularidad",
    },
  ],
  onContinue: async (values: FormikValues, dispatch, state) => {
    try {
      const {
        email,
        foreignTaxDetailsBool,
        bankAccountsBool,
        employmentProfile: {
          employmentStatus,
          years,
          activities,
          bankAccounts,
        },
      } = values;

      if (!state || !dispatch)
        throw new Error("Error: state or dispatch not found");

      const {
        onboardingData: { sendedSteps },
      } = state;

      const getForeignTaxDetails = () => {
        return foreignTaxDetailsBool === "SI"
          ? {
              foreignTaxDetails: {
                country: values.employmentProfile.foreignTaxDetails.country,
                idNumber: values.employmentProfile.foreignTaxDetails.idNumber,
              },
            }
          : {};
      };

      const getBankAccounts = () => {
        const optionalBank = bankAccounts === "" ? [] : [{ cxu: bankAccounts }];

        return bankAccountsBool === "SI" &&
          getCompanyData().companyName === "ALLARIAMAS"
          ? [{ cxu: bankAccounts }]
          : optionalBank;
      };

      const step = {
        "@type": "2_PHYSICAL",
        employmentProfile: {
          employmentStatus,
          ...(years && { years }),
          activities,
          bankAccounts: [...getBankAccounts()],
          ...getForeignTaxDetails(),
        },
      };

      //Remove from sendedSteps the step with the same type. Prevents duplicates
      const filteredSendedSteps = sendedSteps.filter(
        (step: StepEndpoint) => step["@type"] !== "2_PHYSICAL"
      );

      const body = {
        companyType: getCompanyData().companyName,
        personType: state.activeTemplate.onboardingType as PersonType,
        email,
        steps: [...filteredSendedSteps, step],
      };

      await sendStep({ stepId: 2, body });
      logAnalyticsAndHotjarEvent("step_employment_status_completed_pf");
      dispatch(addSendedStep(step));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (
        ["CXU_NOT_OWNER", "CXU_NOT_FOUND"].includes(error?.response?.data?.code)
      ) {
        throw new Error(error.response.data.code);
      } else {
        console.log(error, "Error at step five");
        throw new Error("Error al enviar los datos en el paso 5");
      }
    }
  },
});

const stepSix: Step = {
  step: 6,
  name: "Perfil inversor",
  subSteps: [],
};

const infoStep: Step = {
  step: "information-step",
  component: (dispatch, state, formik) => {
    if (!state || !dispatch || !formik)
      throw new Error("Error: state, dispatch or formik not found");

    const { draftUUID, stepData } = state.onboardingData;
    const { type } = state.infoStep;

    // UTILS
    const isActiveAccountStep = type === "ACTIVE_ACCOUNT";
    const isPendingRegistryStep =
      type === "PENDING_REGISTRY_REMOTE" || type === "PENDING_REGISTRY_LOCAL";
    const isNullStep = type === null;

    const getNextStepAndSubStep = () => {
      let nextStep = 1;
      let nextSubStep = 1;

      if (type === "PENDING_REGISTRY_LOCAL") {
        const { reachedSteps, lastCompletedSubStep } = stepData;
        if (reachedSteps) {
          nextStep = reachedSteps[reachedSteps.length - 1];
          nextSubStep = lastCompletedSubStep + 1;

          return { nextStep, nextSubStep };
        }
      }

      const { telephone, employmentProfile, investmentProfile, gender } =
        formik.values;

      const isFirstSubstepInStepThreeCompleted = gender !== "";

      const isFirstSubstepInStepFourCompleted = telephone !== "";

      const isStepFiveCompleted = employmentProfile?.employmentStatus !== "";

      const isFirstSubstepInStepSixCompleted =
        investmentProfile &&
        Object.values(investmentProfile).filter((value) => value !== "")
          .length > 0;

      // STEP THREE
      if (isFirstSubstepInStepThreeCompleted) {
        nextStep = 3;
        nextSubStep = 2;
      }

      // STEP FOUR
      if (isFirstSubstepInStepFourCompleted) {
        nextStep = 4;
        nextSubStep = 2;
      }

      // STEP FIVE
      if (isStepFiveCompleted) {
        nextStep = 6;
        nextSubStep = 1;
      }

      // STEP SIX
      if (isFirstSubstepInStepSixCompleted) {
        nextStep = 6;
        nextSubStep = 2;
      }

      return { nextStep, nextSubStep };
    };
    // Continue
    const onPrimaryClick = () => {
      const email = formik?.values?.email;
      const cuit = formik?.values?.cuit;
      if (email) {
        Sentry.setUser({ email });
      }
      try {
        if (isActiveAccountStep) {
          dispatch(onUpdateStep(3));
          dispatch(onUpdateReachedStep(3));
          dispatch(onUpdateCompletedStep(2));
        } else if (isPendingRegistryStep) {
          const { nextStep, nextSubStep } = getNextStepAndSubStep();

          //When the user reaches step 3 (even if he does not complete the data) we should not take him to step 1 in case he refreshes the page.
          //So we save the identityDocument in the store to avoid this.
          dispatch(
            onUpdateFormData({
              identityDocument: cuit.slice(2, 10),
            })
          );

          dispatch(onUpdateStep(nextStep));
          dispatch(onUpdateSubStep(nextSubStep));
          if (type === "PENDING_REGISTRY_REMOTE") {
            dispatch(onUpdateReachedStep(nextStep));
            dispatch(onUpdateCompletedStep(nextStep - 1));
          }
        } else if (isNullStep) {
          document.location.href =
            "https://business-onboarding.allariamas.com.ar/";
        }
        logAnalyticsAndHotjarEvent("onboarding_resumed");
      } catch (error) {
        console.log(error);
        showToastMessage(genericToastifyError, {
          containerId: "informationAlert",
          type: "error",
        });
      }
    };

    // Restart or back
    const onSecondaryClick = async () => {
      try {
        if (type === "ACTIVE_ACCOUNT") {
          dispatch(onUpdateStep(1));
        } else if (
          type === "PENDING_REGISTRY_REMOTE" ||
          type === "PENDING_REGISTRY_LOCAL"
        ) {
          const { cuit, email, referredBool, referrer, identityDocument } =
            formik.values;
          const { token, signatureRequestId } = state.authentication;
          const tempValues = {
            token,
            signatureRequestId,
            cuit,
            email,
            referredBool,
            referrer,
            draftUUID,
            identityDocument,
          };

          await deleteIndividualDraft({ draftUUID });

          //Delete dniImages and livenessValidation documents
          const db = getFirestore();
          const docFilesRef = doc(db, "dniImages", tempValues.draftUUID);
          const docLivenessRef = doc(
            db,
            "livenessValidation",
            tempValues.draftUUID
          );

          await deleteDoc(docFilesRef);
          await deleteDoc(docLivenessRef);

          dispatch({ type: "reset-store" });

          if (type === "PENDING_REGISTRY_REMOTE") {
            formik.resetForm({
              values: {
                cuit: tempValues.cuit,
                email: tempValues.email,
                referrer: tempValues.referrer,
                referredBool: tempValues.referredBool,
                identityDocument:
                  tempValues.identityDocument || tempValues.cuit.slice(2, 10),
              },
            });

            dispatch(onUpdateDraftUUID(tempValues.draftUUID));
            dispatch(updateSignatureRequestId(tempValues.signatureRequestId));
            dispatch(updateToken(tempValues.token));
            dispatch(onUpdateCompletedStep(2));
            dispatch(onUpdateStep(3));
            dispatch(onUpdateReachedStep(3));
          } else if (type === "PENDING_REGISTRY_LOCAL") {
            formik.resetForm();
            dispatch(onUpdateStep(1));
            dispatch(onUpdateReachedStep(1));
          }
          logAnalyticsAndHotjarEvent("onboarding_restart");
        } else if (type === null) {
          dispatch(onUpdateStep(1));
          dispatch(onUpdateReachedStep(1));
        }
      } catch (error) {
        showToastMessage(genericToastifyError, {
          containerId: "informationAlert",
          type: "error",
        });
      }
    };

    let primaryText = "Retomar registro!";
    let secondaryText = "Empezar de nuevo!";
    let title = "";
    let subtitle = "";

    if (
      type === "PENDING_REGISTRY_LOCAL" ||
      type === "PENDING_REGISTRY_REMOTE"
    ) {
      primaryText = "Retomar registro";
      secondaryText = "Empezar de nuevo";
      title = "Tenés un registro sin terminar";
      subtitle = "¿Deseás retomar desde donde lo dejaste?";
    } else if (type === "ACTIVE_ACCOUNT") {
      primaryText = "Continuar";
      secondaryText = "";
      title = "¡Un proceso veloz!";
      subtitle = "Agilizamos la apertura precargando tus datos.";
    } else if (type === null) {
      //Error CUIT
      primaryText = "Continuar";
      secondaryText = "Volver";
      title = "El CUIT corresponde a una persona jurídica";
      subtitle = "¿Deseás continuar y registrar una cuenta empresarial?";
    }

    const primarybutton = {
      text: primaryText,
      onClick: onPrimaryClick,
    };
    const secondarybutton = {
      text: secondaryText,
      onClick: onSecondaryClick,
    };

    return (
      <InteractiveInformation
        primaryButton={primarybutton}
        secondaryButton={secondarybutton}
        title={title}
        subtitle={subtitle}
      />
    );
  },
  hideLayoutButtons: true,
  hideStepper: true,
};

const onboardingPhysical = async (): Promise<Template> => {
  try {
    const [activities, countries] = await axios.all([
      getActivities(),
      getCountries(),
    ]);

    const countriesTyped = countries as PitbullCountry[];

    const activitiesOptions: Option[] = activities?.map(({ name }) => {
      return { value: name, label: name };
    });

    const countriesOptions: Option[] = countriesTyped?.map(({ name }) => {
      return { value: name, label: name };
    });
    const citizenshipOptions: Option[] = countriesTyped?.map(
      ({ citizenship }) => {
        return { value: citizenship, label: citizenship };
      }
    );

    const questions = await getQuestions();

    const formattedQuestions = questionsToFields(questions);
    const isDev = import.meta.env.VITE_ENVIRONMENT === "dev";

    //Parse uuid dev-prod
    const questionHeritageUUID = isDev
      ? "investmentProfile.3bf7f3bf-5717-4cfe-9002-2890ee8e2838"
      : "investmentProfile.dc93dce4-b113-4b2f-b2a9-2584a46ff6b6";
    const questionMonthlyIncomeUUID = isDev
      ? "investmentProfile.e92d83e2-c1da-4cec-bb4b-4c785bc0d78d"
      : "investmentProfile.8494bf9f-475a-4296-8dfe-6ae19b33d04b";

    const indexOfHeritageOrigin = formattedQuestions.findIndex(
      (field) => field.name === questionHeritageUUID
    );
    const maxHeritageFields = 5;
    const heritageFields: Field[] = [
      {
        conditional: [
          {
            name: questionHeritageUUID,
            value: "Más de $80.000.000",
          },
          {
            name: questionMonthlyIncomeUUID,
            value: "Más de $5.000.000",
          },
          {
            name: "pep",
            value: ["FAMILY", "DIRECT"],
          },
        ],
        name: "investmentProfile.heritageFile1",
        type: "file",
        label: "Documentación patrimonial",
        validation: fileRequired(validDniTypes, maxFileSize),
        initialValue: "",
        infoText: `Cargá un archivo JPG, PNG, HEIC, HEIF o PDF de hasta ${maxFileSize}mb.`,
        infoTextPosition: "top",
        maxSize: maxFileSize,
        validTypes: validDniTypes,
        groupReorder: "heritage",
      } as Field,
    ];

    for (let i = 2; i <= maxHeritageFields; i++) {
      heritageFields.push({
        conditionalFile: {
          name: `investmentProfile.heritageFile${i - 1}`,
        },
        name: `investmentProfile.heritageFile${i}`,
        type: "file",
        label: "",
        validation: fileRequired(validDniTypes, maxFileSize),
        initialValue: "",
        maxSize: maxFileSize,
        validTypes: validDniTypes,
        groupReorder: "heritage",
      } as Field);
    }

    // Add new fields dynamically after the "investmentProfile.b2ea30a0-9574-4196-bbce-9812a98020bd" question
    formattedQuestions.splice(indexOfHeritageOrigin + 1, 0, ...heritageFields);

    const individualSubsteps = [1, 2];

    const requestStepSix = async (
      values: FormikValues,
      dispatch: AppDispatch,
      state: RootState
    ) => {
      try {
        if (!state || !dispatch)
          throw new Error("Error: state or dispatch not found");

        // Find the external code of the value (the answer of question)
        // Each question has a externalCode and each answer too, so we need to find the externalCode of the answer about the question
        const findExternalCodeOfValue = (externalCode: string) => {
          const question = questions.find(
            (question) => question.externalCode === externalCode
          );
          return question?.choices.find(
            (choice) => choice.option === values.investmentProfile[externalCode]
          );
        };

        const {
          email,
          investmentProfile,
          //  cuit
        } = values;

        const choices = Object.keys(investmentProfile);

        //Remove the file field because it doesn't have any choices
        const elementsToRemove = heritageFields.map((field) => {
          const nestedName = field.name.split(".")[1];
          return nestedName;
        });

        const choicesFiltered = choices.filter(
          (item) => !elementsToRemove.includes(item)
        );

        const choicesParsed = choicesFiltered
          .map((choice) => findExternalCodeOfValue(choice)?.externalCode)
          .filter((code): code is string => code !== undefined);

        const {
          onboardingData: { sendedSteps },
        } = state;

        const step = {
          "@type": "3_PHYSICAL",
          investmentProfile: {
            choices: [...choicesParsed],
          },
        };

        //Remove from sendedSteps the step with the same type. Prevents duplicates
        const filteredSendedSteps = sendedSteps.filter(
          (step: StepEndpoint) => step["@type"] !== "3_PHYSICAL"
        );

        const body = {
          companyType: getCompanyData().companyName,
          personType: state.activeTemplate.onboardingType as PersonType,
          email,
          steps: [...filteredSendedSteps, step],
        };
        await sendStep({ stepId: 3, body });

        const heritageFileKeys = Object.keys(investmentProfile).filter((key) =>
          key.startsWith("heritageFile")
        );

        for (const key of heritageFileKeys) {
          const file = investmentProfile[key];
          if (file) {
            await sendIndividualDocument({
              draftUUID: state.onboardingData.draftUUID,
              documentType: "WORTH_STATEMENT",
              file: file,
              // cuit,
            });
          }
        }
        logAnalyticsAndHotjarEvent("step_investor_profile_completed_pf");
        dispatch(addSendedStep(step));
      } catch (error) {
        console.log(error, "Error at step six");
        throw new Error("Error al enviar los datos en el paso 6");
      }
    };
    const subSteps = individualSubsteps.map((subStep, pos) => {
      return {
        step: subStep,
        subtitle:
          pos === 0
            ? "El riesgo a tomar según el tipo de inversiones."
            : "Declaración jurada de ingresos.",
        onContinue: pos === 1 ? requestStepSix : () => null,
        titleOfStep: pos === 0 ? "Perfil inversor" : "Información patrimonial",
        fields: formattedQuestions
          .map((field, index) => {
            if ((subStep === 1 && index < 5) || (subStep === 2 && index >= 5)) {
              return field;
            } else {
              return null;
            }
          })
          .filter((field) => !!field) as Field[],
      };
    });

    const stepSixWithSubsteps = {
      ...stepSix,
      subSteps,
    };

    const stepFourFields = (
      stepFour(countriesOptions, citizenshipOptions).subSteps ?? []
    ).flatMap((subStep: BasicStep) => subStep.fields ?? []);

    const stepFourFieldsFormatted = stepFourFields.map(
      ({
        name,
        label,
        type,
        options,
        conditional,
        preconfirmationLabel,
        formatInPreConfirmation,
      }) => ({
        name,
        label: preconfirmationLabel ?? label,
        type,
        options,
        isConditional: !!conditional,
        formatInPreConfirmation,
      })
    );

    const firstPreconfirmationStep: PreconfirmationStep = {
      name: "Datos personales",
      columns: 4,
      fields: [
        {
          name: "cuit",
          label: "CUIT / CUIL",
          type: "cuit",
          formatInPreConfirmation: (value) => asCuit(value),
          isConditional: false,
        },
        ...stepFourFieldsFormatted.slice(0, 9),
        ...stepFourFieldsFormatted.slice(11, 18),
        ...stepFourFieldsFormatted.slice(9, 11),
      ],
      editStep: {
        step: 4,
        substep: 1,
      },
    };

    const stepFiveFields =
      stepFive(activitiesOptions, countriesOptions).fields ?? [];

    const stepFiveFieldsFormatted = stepFiveFields.map(
      ({
        name,
        label,
        type,
        options,
        conditional,
        preconfirmationLabel,
        formatInPreConfirmation,
      }) => ({
        name,
        label: preconfirmationLabel ?? label,
        type,
        options,
        isConditional: !!conditional,
        formatInPreConfirmation,
      })
    );

    const secondPreconfirmationStep: PreconfirmationStep = {
      name: "Situación laboral",
      columns: 4,
      fields: [
        ...stepFiveFieldsFormatted.slice(0, 3),
        ...stepFiveFieldsFormatted.slice(4, 8),
      ],
      editStep: {
        step: 5,
      },
    };

    const stepSixFields = (stepSixWithSubsteps.subSteps ?? []).flatMap(
      (subStep) => subStep.fields ?? []
    );

    const stepSixFieldsFormatted = stepSixFields.map(
      ({
        name,
        label,
        type,
        options,
        conditional,
        formatInPreConfirmation,
      }) => ({
        name,
        label: label,
        type,
        options,
        isConditional: !!conditional,
        formatInPreConfirmation,
      })
    );

    const thirdPreconfirmationStep: PreconfirmationStep = {
      name: "Perfil inversor",
      columns: 2,
      fields: [
        ...stepSixFieldsFormatted.slice(0, 5),
        stepSixFieldsFormatted[7],
        stepSixFieldsFormatted[5],
        stepSixFieldsFormatted[6],
      ],
      editStep: {
        step: 6,
        substep: 1,
      },
    };

    const handleSendTermsAndConditions = async (
      state: RootState,
      values: FormikValues
    ) => {
      try {
        const {
          onboardingData: { sendedSteps, formValues },
          activeTemplate: { onboardingType },
        } = state;
        const step: StepFourPF = {
          "@type": "4_PHYSICAL",
          acceptedOptionalTermsAndConditions: values?.senebiAndExteriorNotes,
          acceptedTermsAndConditions: values?.termsAndConditions,
        };

        if (getCompanyData().companyName === "AGROFINANZAS") {
          step.wantsAllariaAccount = !!values?.wantsAllariaAccount;
        }

        //Remove from sendedSteps the step with the same type. Prevents duplicates
        const filteredSendedSteps = sendedSteps.filter(
          (step: StepEndpoint) => step["@type"] !== "4_PHYSICAL"
        );

        const body = {
          companyType: getCompanyData().companyName,
          personType: onboardingType as PersonType,
          email: formValues?.email,
          steps: [...filteredSendedSteps, step],
        };

        await sendStep({ stepId: 4, body });
      } catch (error) {
        console.log(error, "error");
      }
    };

    const { favicon, formTitle, logo } = getCompanyData();
    return {
      onboardingType: "PHYSICAL",
      formTitle,
      logo,
      icon: bodyIcon,
      favicon,
      steps: [
        stepOne,
        stepTwo,
        stepThree(countriesTyped),
        stepFour(countriesOptions, citizenshipOptions),
        stepFive(activitiesOptions, countriesOptions),
        stepSixWithSubsteps,
        infoStep,
      ],
      preconfirmation: {
        steps: [
          firstPreconfirmationStep,
          secondPreconfirmationStep,
          thirdPreconfirmationStep,
        ],
        checkboxes: getCompanyData().termsAndConditions.map((term) => ({
          ...term,
          initialValue: false,
          validation: term.required ? checkboxRequired : checkbox,
        })),
        sendTermsAndConditions: handleSendTermsAndConditions,
      },
    };
  } catch (error) {
    throw new Error(error as string);
  }
};
export default onboardingPhysical;
